// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
  function id(x) {
    return x[0];
  }
  var grammar = {
    Lexer: undefined,
    ParserRules: [
      { name: 'phraseCommand$subexpression$1$ebnf$1', symbols: ['startPhrase'] },
      {
        name: 'phraseCommand$subexpression$1$ebnf$1',
        symbols: ['phraseCommand$subexpression$1$ebnf$1', 'startPhrase'],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        },
      },
      { name: 'phraseCommand$subexpression$1', symbols: ['phraseCommand$subexpression$1$ebnf$1'] },
      { name: 'phraseCommand$subexpression$2$ebnf$1', symbols: ['command'], postprocess: id },
      {
        name: 'phraseCommand$subexpression$2$ebnf$1',
        symbols: [],
        postprocess: function (d) {
          return null;
        },
      },
      { name: 'phraseCommand$subexpression$2', symbols: ['phraseCommand$subexpression$2$ebnf$1'] },
      {
        name: 'phraseCommand',
        symbols: ['phraseCommand$subexpression$1', 'phraseCommand$subexpression$2'],
        postprocess: function (d) {
          return d[1];
        },
      },
      { name: 'command', symbols: ['volumeDown'] },
      { name: 'command', symbols: ['volumeUp'] },
      { name: 'command', symbols: ['helpCall'] },
      { name: 'command', symbols: ['sceneChange'] },
      { name: 'command', symbols: ['lightDown'] },
      { name: 'command', symbols: ['lightUp'] },
      { name: 'command', symbols: ['saunaTemperatureChange'] },
      { name: 'command', symbols: ['sessionLength'] },
      { name: 'command', symbols: ['musicPlay'] },
      { name: 'command', symbols: ['musicPause'] },
      {
        name: 'volumeDown$subexpression$1$string$1',
        symbols: [
          { literal: 'l' },
          { literal: 'e' },
          { literal: 'i' },
          { literal: 's' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'volumeDown$subexpression$1', symbols: ['volumeDown$subexpression$1$string$1'] },
      {
        name: 'volumeDown$subexpression$1$string$2',
        symbols: [
          { literal: 'm' },
          { literal: 'u' },
          { literal: 's' },
          { literal: 'i' },
          { literal: 'k' },
          { literal: ' ' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: 'i' },
          { literal: 's' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'volumeDown$subexpression$1', symbols: ['volumeDown$subexpression$1$string$2'] },
      {
        name: 'volumeDown$subexpression$1$string$3',
        symbols: [
          { literal: 'l' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 't' },
          { literal: 's' },
          { literal: 't' },
          { literal: 'ä' },
          { literal: 'r' },
          { literal: 'k' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'n' },
          { literal: 'i' },
          { literal: 'e' },
          { literal: 'd' },
          { literal: 'r' },
          { literal: 'i' },
          { literal: 'g' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'volumeDown$subexpression$1', symbols: ['volumeDown$subexpression$1$string$3'] },
      {
        name: 'volumeDown',
        symbols: ['volumeDown$subexpression$1'],
        postprocess: function (d) {
          return ['volumeDown'];
        },
      },
      {
        name: 'volumeUp$subexpression$1$string$1',
        symbols: [
          { literal: 'l' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'volumeUp$subexpression$1', symbols: ['volumeUp$subexpression$1$string$1'] },
      {
        name: 'volumeUp$subexpression$1$string$2',
        symbols: [
          { literal: 'm' },
          { literal: 'u' },
          { literal: 's' },
          { literal: 'i' },
          { literal: 'k' },
          { literal: ' ' },
          { literal: 'l' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'volumeUp$subexpression$1', symbols: ['volumeUp$subexpression$1$string$2'] },
      {
        name: 'volumeUp$subexpression$1$string$3',
        symbols: [
          { literal: 'l' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 't' },
          { literal: 's' },
          { literal: 't' },
          { literal: 'ä' },
          { literal: 'r' },
          { literal: 'k' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'h' },
          { literal: 'ö' },
          { literal: 'h' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'volumeUp$subexpression$1', symbols: ['volumeUp$subexpression$1$string$3'] },
      {
        name: 'volumeUp',
        symbols: ['volumeUp$subexpression$1'],
        postprocess: function (d) {
          return ['volumeUp'];
        },
      },
      {
        name: 'musicPlay$subexpression$1$string$1',
        symbols: [
          { literal: 'm' },
          { literal: 'u' },
          { literal: 's' },
          { literal: 'i' },
          { literal: 'k' },
          { literal: ' ' },
          { literal: 'w' },
          { literal: 'e' },
          { literal: 'i' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'musicPlay$subexpression$1', symbols: ['musicPlay$subexpression$1$string$1'] },
      {
        name: 'musicPlay',
        symbols: ['musicPlay$subexpression$1'],
        postprocess: function (d) {
          return ['musicPlay'];
        },
      },
      {
        name: 'musicPause$subexpression$1$string$1',
        symbols: [
          { literal: 'm' },
          { literal: 'u' },
          { literal: 's' },
          { literal: 'i' },
          { literal: 'k' },
          { literal: ' ' },
          { literal: 'p' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 's' },
          { literal: 'e' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'musicPause$subexpression$1', symbols: ['musicPause$subexpression$1$string$1'] },
      {
        name: 'musicPause$subexpression$1$string$2',
        symbols: [
          { literal: 'm' },
          { literal: 'u' },
          { literal: 's' },
          { literal: 'i' },
          { literal: 'k' },
          { literal: ' ' },
          { literal: 's' },
          { literal: 't' },
          { literal: 'o' },
          { literal: 'p' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'musicPause$subexpression$1', symbols: ['musicPause$subexpression$1$string$2'] },
      {
        name: 'musicPause',
        symbols: ['musicPause$subexpression$1'],
        postprocess: function (d) {
          return ['musicPause'];
        },
      },
      {
        name: 'helpCall$subexpression$1$string$1',
        symbols: [
          { literal: 'h' },
          { literal: 'i' },
          { literal: 'l' },
          { literal: 'f' },
          { literal: 'e' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'helpCall$subexpression$1', symbols: ['helpCall$subexpression$1$string$1'] },
      {
        name: 'helpCall$subexpression$1$string$2',
        symbols: [
          { literal: 'i' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: ' ' },
          { literal: 'b' },
          { literal: 'r' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'h' },
          { literal: 'i' },
          { literal: 'l' },
          { literal: 'f' },
          { literal: 'e' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'helpCall$subexpression$1', symbols: ['helpCall$subexpression$1$string$2'] },
      {
        name: 'helpCall',
        symbols: ['helpCall$subexpression$1'],
        postprocess: function (d) {
          return ['helpCall'];
        },
      },
      {
        name: 'sceneChange$subexpression$1$subexpression$1$string$1',
        symbols: [
          { literal: 's' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'd' },
          { literal: 'i' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 's' },
          { literal: 'z' },
          { literal: 'e' },
          { literal: 'n' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'f' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sceneChange$subexpression$1$subexpression$1',
        symbols: ['sceneChange$subexpression$1$subexpression$1$string$1', '_', 'scene'],
      },
      {
        name: 'sceneChange$subexpression$1',
        symbols: ['sceneChange$subexpression$1$subexpression$1'],
      },
      {
        name: 'sceneChange$subexpression$1$subexpression$2$string$1',
        symbols: [
          { literal: 's' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 's' },
          { literal: 'z' },
          { literal: 'e' },
          { literal: 'n' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'f' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sceneChange$subexpression$1$subexpression$2',
        symbols: ['sceneChange$subexpression$1$subexpression$2$string$1', '_', 'scene'],
      },
      {
        name: 'sceneChange$subexpression$1',
        symbols: ['sceneChange$subexpression$1$subexpression$2'],
      },
      {
        name: 'sceneChange$subexpression$1$subexpression$3$string$1',
        symbols: [
          { literal: 's' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 's' },
          { literal: 'e' },
          { literal: 'h' },
          { literal: 'n' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'f' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sceneChange$subexpression$1$subexpression$3',
        symbols: ['sceneChange$subexpression$1$subexpression$3$string$1', '_', 'scene'],
      },
      {
        name: 'sceneChange$subexpression$1',
        symbols: ['sceneChange$subexpression$1$subexpression$3'],
      },
      {
        name: 'sceneChange',
        symbols: ['sceneChange$subexpression$1'],
        postprocess: function (d) {
          return ['sceneChange', d[0][0][2][0]];
        },
      },
      {
        name: 'lightDown$subexpression$1$string$1',
        symbols: [
          { literal: 'l' },
          { literal: 'i' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: 't' },
          { literal: ' ' },
          { literal: 'd' },
          { literal: 'u' },
          { literal: 'n' },
          { literal: 'k' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'lightDown$subexpression$1', symbols: ['lightDown$subexpression$1$string$1'] },
      {
        name: 'lightDown',
        symbols: ['lightDown$subexpression$1'],
        postprocess: function (d) {
          return ['lightDown'];
        },
      },
      {
        name: 'lightUp$subexpression$1$string$1',
        symbols: [
          { literal: 'l' },
          { literal: 'i' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: 't' },
          { literal: ' ' },
          { literal: 'h' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: 'r' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'lightUp$subexpression$1', symbols: ['lightUp$subexpression$1$string$1'] },
      {
        name: 'lightUp',
        symbols: ['lightUp$subexpression$1'],
        postprocess: function (d) {
          return ['lightUp'];
        },
      },
      {
        name: 'saunaTemperatureChange$subexpression$1$subexpression$1$string$1',
        symbols: [
          { literal: 's' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'd' },
          { literal: 'i' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 's' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'n' },
          { literal: 'a' },
          { literal: ' ' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'f' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'saunaTemperatureChange$subexpression$1$subexpression$1',
        symbols: [
          'saunaTemperatureChange$subexpression$1$subexpression$1$string$1',
          '_',
          'temperature',
        ],
      },
      {
        name: 'saunaTemperatureChange$subexpression$1',
        symbols: ['saunaTemperatureChange$subexpression$1$subexpression$1'],
      },
      {
        name: 'saunaTemperatureChange$subexpression$1$subexpression$2$string$1',
        symbols: [
          { literal: 's' },
          { literal: 't' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'd' },
          { literal: 'i' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 's' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'n' },
          { literal: 'a' },
          { literal: ' ' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'f' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'saunaTemperatureChange$subexpression$1$subexpression$2$string$2',
        symbols: [{ literal: 'g' }, { literal: 'r' }, { literal: 'a' }, { literal: 'd' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'saunaTemperatureChange$subexpression$1$subexpression$2',
        symbols: [
          'saunaTemperatureChange$subexpression$1$subexpression$2$string$1',
          '_',
          'temperature',
          '_',
          'saunaTemperatureChange$subexpression$1$subexpression$2$string$2',
        ],
      },
      {
        name: 'saunaTemperatureChange$subexpression$1',
        symbols: ['saunaTemperatureChange$subexpression$1$subexpression$2'],
      },
      {
        name: 'saunaTemperatureChange',
        symbols: ['saunaTemperatureChange$subexpression$1'],
        postprocess: function (d) {
          return ['saunaTemperatureChange', d[0][0][2]];
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$string$1',
        symbols: [
          { literal: 'w' },
          { literal: 'i' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'l' },
          { literal: 'a' },
          { literal: 'n' },
          { literal: 'g' },
          { literal: 'e' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$1$string$1',
        symbols: [{ literal: 'g' }, { literal: 'e' }, { literal: 'h' }, { literal: 't' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$1',
        symbols: ['sessionLength$subexpression$1$subexpression$1$subexpression$1$string$1'],
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$1$string$2',
        symbols: [
          { literal: 'd' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'e' },
          { literal: 'r' },
          { literal: 't' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$1',
        symbols: ['sessionLength$subexpression$1$subexpression$1$subexpression$1$string$2'],
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$string$2',
        symbols: [
          { literal: 'm' },
          { literal: 'e' },
          { literal: 'i' },
          { literal: 'n' },
          { literal: 'e' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$2$string$1',
        symbols: [
          { literal: 's' },
          { literal: 'e' },
          { literal: 's' },
          { literal: 's' },
          { literal: 'i' },
          { literal: 'o' },
          { literal: 'n' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$2',
        symbols: ['sessionLength$subexpression$1$subexpression$1$subexpression$2$string$1'],
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$2$string$2',
        symbols: [
          { literal: 's' },
          { literal: 'i' },
          { literal: 't' },
          { literal: 'z' },
          { literal: 'u' },
          { literal: 'n' },
          { literal: 'g' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$2',
        symbols: ['sessionLength$subexpression$1$subexpression$1$subexpression$2$string$2'],
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$3$ebnf$1$string$1',
        symbols: [{ literal: 'n' }, { literal: 'o' }, { literal: 'c' }, { literal: 'h' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$3$ebnf$1',
        symbols: ['sessionLength$subexpression$1$subexpression$1$subexpression$3$ebnf$1$string$1'],
        postprocess: id,
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$3$ebnf$1',
        symbols: [],
        postprocess: function (d) {
          return null;
        },
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1$subexpression$3',
        symbols: ['sessionLength$subexpression$1$subexpression$1$subexpression$3$ebnf$1'],
      },
      {
        name: 'sessionLength$subexpression$1$subexpression$1',
        symbols: [
          'sessionLength$subexpression$1$subexpression$1$string$1',
          '_',
          'sessionLength$subexpression$1$subexpression$1$subexpression$1',
          '_',
          'sessionLength$subexpression$1$subexpression$1$string$2',
          '_',
          'sessionLength$subexpression$1$subexpression$1$subexpression$2',
          'sessionLength$subexpression$1$subexpression$1$subexpression$3',
        ],
      },
      {
        name: 'sessionLength$subexpression$1',
        symbols: ['sessionLength$subexpression$1$subexpression$1'],
      },
      {
        name: 'sessionLength$subexpression$1$string$1',
        symbols: [
          { literal: 'w' },
          { literal: 'i' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'l' },
          { literal: 'a' },
          { literal: 'n' },
          { literal: 'g' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'h' },
          { literal: 'a' },
          { literal: 'b' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'i' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: ' ' },
          { literal: 'n' },
          { literal: 'o' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: ' ' },
          { literal: 'd' },
          { literal: 'e' },
          { literal: 'n' },
          { literal: ' ' },
          { literal: 'r' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'm' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1',
        symbols: ['sessionLength$subexpression$1$string$1'],
      },
      {
        name: 'sessionLength$subexpression$1$string$2',
        symbols: [
          { literal: 'w' },
          { literal: 'i' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'l' },
          { literal: 'a' },
          { literal: 'n' },
          { literal: 'g' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'h' },
          { literal: 'a' },
          { literal: 'b' },
          { literal: 'e' },
          { literal: ' ' },
          { literal: 'i' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: ' ' },
          { literal: 'n' },
          { literal: 'o' },
          { literal: 'c' },
          { literal: 'h' },
          { literal: ' ' },
          { literal: 'z' },
          { literal: 'e' },
          { literal: 'i' },
          { literal: 't' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      {
        name: 'sessionLength$subexpression$1',
        symbols: ['sessionLength$subexpression$1$string$2'],
      },
      {
        name: 'sessionLength',
        symbols: ['sessionLength$subexpression$1'],
        postprocess: function (d) {
          return ['sessionLength'];
        },
      },
      {
        name: 'temperature$subexpression$1$string$1',
        symbols: [{ literal: '4' }, { literal: '0' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$1'] },
      {
        name: 'temperature$subexpression$1$string$2',
        symbols: [{ literal: '4' }, { literal: '5' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$2'] },
      {
        name: 'temperature$subexpression$1$string$3',
        symbols: [{ literal: '5' }, { literal: '0' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$3'] },
      {
        name: 'temperature$subexpression$1$string$4',
        symbols: [{ literal: '5' }, { literal: '5' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$4'] },
      {
        name: 'temperature$subexpression$1$string$5',
        symbols: [{ literal: '6' }, { literal: '0' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$5'] },
      {
        name: 'temperature$subexpression$1$string$6',
        symbols: [{ literal: '6' }, { literal: '5' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$6'] },
      {
        name: 'temperature$subexpression$1$string$7',
        symbols: [{ literal: '7' }, { literal: '0' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$7'] },
      {
        name: 'temperature$subexpression$1$string$8',
        symbols: [{ literal: '7' }, { literal: '5' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$8'] },
      {
        name: 'temperature$subexpression$1$string$9',
        symbols: [{ literal: '8' }, { literal: '0' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'temperature$subexpression$1', symbols: ['temperature$subexpression$1$string$9'] },
      {
        name: 'temperature',
        symbols: ['temperature$subexpression$1'],
        postprocess: function (d) {
          return parseInt(d);
        },
      },
      {
        name: 'scene$string$1',
        symbols: [
          { literal: 's' },
          { literal: 't' },
          { literal: 'r' },
          { literal: 'a' },
          { literal: 'n' },
          { literal: 'd' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'scene', symbols: ['scene$string$1'] },
      {
        name: 'scene$string$2',
        symbols: [{ literal: 'b' }, { literal: 'e' }, { literal: 'r' }, { literal: 'g' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'scene', symbols: ['scene$string$2'] },
      {
        name: 'scene$string$3',
        symbols: [
          { literal: 's' },
          { literal: 't' },
          { literal: 'a' },
          { literal: 'd' },
          { literal: 't' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'scene', symbols: ['scene$string$3'] },
      {
        name: 'scene$string$4',
        symbols: [
          { literal: 'w' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 't' },
          { literal: 'r' },
          { literal: 'a' },
          { literal: 'u' },
          { literal: 'm' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'scene', symbols: ['scene$string$4'] },
      {
        name: 'startPhrase$subexpression$1$string$1',
        symbols: [{ literal: 'h' }, { literal: 'e' }, { literal: 'y' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'startPhrase$subexpression$1', symbols: ['startPhrase$subexpression$1$string$1'] },
      {
        name: 'startPhrase',
        symbols: ['startPhrase$subexpression$1', '_', 'welly', '_'],
        postprocess: function (d) {
          return 'startPhrase';
        },
      },
      {
        name: 'welly$string$1',
        symbols: [
          { literal: 'w' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'y' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$1'] },
      {
        name: 'welly$string$2',
        symbols: [
          { literal: 'w' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'i' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$2'] },
      {
        name: 'welly$string$3',
        symbols: [
          { literal: 'm' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'i' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$3'] },
      {
        name: 'welly$string$4',
        symbols: [
          { literal: 'm' },
          { literal: 'e' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'y' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$4'] },
      {
        name: 'welly$string$5',
        symbols: [
          { literal: 'w' },
          { literal: 'i' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'i' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$5'] },
      {
        name: 'welly$string$6',
        symbols: [
          { literal: 'w' },
          { literal: 'i' },
          { literal: 'l' },
          { literal: 'l' },
          { literal: 'y' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$6'] },
      {
        name: 'welly$string$7',
        symbols: [{ literal: 'w' }, { literal: 'e' }, { literal: 'r' }],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$7'] },
      {
        name: 'welly$string$8',
        symbols: [
          { literal: 'b' },
          { literal: 'e' },
          { literal: 'n' },
          { literal: 'n' },
          { literal: 'i' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$8'] },
      {
        name: 'welly$string$9',
        symbols: [
          { literal: 'b' },
          { literal: 'e' },
          { literal: 'n' },
          { literal: 'n' },
          { literal: 'y' },
        ],
        postprocess: function joiner(d) {
          return d.join('');
        },
      },
      { name: 'welly', symbols: ['welly$string$9'] },
      { name: '_', symbols: [{ literal: ' ' }] },
    ],
    ParserStart: 'phraseCommand',
  };
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = grammar;
  } else {
    window.grammar = grammar;
  }
})();
