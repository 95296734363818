<ng-container *ngIf="kioskService.room?.state === 'OCCUPIED'">
  <div class="app-navigation">
    <svg (click)="kioskService.openWelcomeModal() "
      class="app-navigation-logo"
      width="47"
      height="26"
      viewBox="0 0 47 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5455 21.5221C14.5227 21.8972 13.4091 22.1082 12.25 22.1082C11.0682 22.1082 9.95454 21.8738 8.95455 21.4283C7.43182 20.7719 6.13636 19.6465 5.22727 18.2633C4.31818 16.8801 3.77273 15.1921 3.77273 13.3634V2.86023H0V13.3634C0 15.0983 0.340909 16.7628 0.954545 18.2867C1.88636 20.5609 3.43182 22.4833 5.38636 23.8431C7.34091 25.2029 9.70454 26 12.2273 26C14.3864 26 16.4773 25.4608 18.3182 24.4995C17.25 23.6555 16.3182 22.6474 15.5455 21.5221Z"
        fill="#BCA090"
      />
      <path
        d="M35.1591 5.3688V13.3634C35.1591 14.5825 34.9318 15.7313 34.5 16.7628C33.8636 18.3336 32.7727 19.67 31.4318 20.6078C30.0909 21.5455 28.4545 22.1082 26.7045 22.1082C25.5227 22.1082 24.4091 21.8738 23.4091 21.4283C23.1364 21.3111 22.8636 21.1704 22.5909 21.0063C21.4773 20.3733 20.5227 19.4824 19.7727 18.404C19.75 18.3571 19.7045 18.3102 19.6591 18.2633C18.75 16.8566 18.2045 15.1921 18.2045 13.3868V2.88368H14.4318V13.3634C14.4318 15.0983 14.7727 16.7628 15.3864 18.2867C15.7727 19.2245 16.2727 20.1154 16.8409 20.9125C17.6136 21.991 18.5682 22.9288 19.6364 23.7024C19.7045 23.7493 19.75 23.7962 19.8182 23.8431C21.7727 25.2029 24.1364 26 26.6591 26C28.3409 26 29.9545 25.6483 31.4318 25.0153C33.6364 24.0541 35.5 22.4599 36.8182 20.4436C38.1364 18.4274 38.9091 15.9892 38.9091 13.3868V6.79892C38.4545 6.44725 37.9545 6.14247 37.4318 5.90803C36.7273 5.60325 35.9773 5.41569 35.1591 5.3688Z"
        fill="#BCA090"
      />
      <path
        d="M45.9773 7.71326C45.0455 5.43913 43.5 3.51668 41.5455 2.1569C39.5909 0.797114 37.2273 0 34.7045 0C31.2045 0 27.9318 1.38323 25.5455 3.70424C24.3409 4.87647 23.3636 6.25969 22.6818 7.83048C22 9.40126 21.6136 11.1596 21.6136 12.9883C21.6136 14.2777 21.3409 15.4734 20.8636 16.5518C20.7955 16.716 20.7045 16.8801 20.6136 17.0442C20.6818 17.1614 20.75 17.3021 20.8409 17.4193C21.5 18.4274 22.3864 19.248 23.4318 19.8106C23.7727 19.2714 24.0455 18.7322 24.3182 18.1461C25 16.5753 25.3864 14.817 25.3864 12.9883C25.3864 11.6988 25.6591 10.5032 26.1364 9.42471C26.8409 7.80703 28.0455 6.40036 29.5455 5.41569C31.0455 4.43102 32.8409 3.86835 34.75 3.86835C34.9091 3.86835 35.0682 3.86835 35.2045 3.89179C36.2045 3.93868 37.1591 4.17313 38.0455 4.57169C38.3636 4.71235 38.6818 4.87647 38.9773 5.06402C40.0909 5.72047 41.0455 6.65825 41.7727 7.7367C42.6818 9.14337 43.2273 10.8079 43.2273 12.6132V23.1163H47V12.6366C46.9545 10.9017 46.5909 9.23715 45.9773 7.71326Z"
        fill="#BCA090"
      />
    </svg>
    <hr class="app-navigation-separator" />
    <div class="app-navigation-entries">
      <!-- Dashboard -->
      <div class="app-navigation-entry" routerLink="dashboard" routerLinkActive="active">
        <svg
          class="app-navigation-entry-icon"
          width="20"
          height="17"
          viewBox="0 0 20 17"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z" fill="inherit" />
        </svg>
      </div>
      <!-- Shop -->
      <div class="app-navigation-entry" routerLink="shop" routerLinkActive="active">
        <svg
          class="app-navigation-entry-icon"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.59L4.25 12.04C4.09 12.32 4 12.65 4 13C4 14.1 4.9 15 6 15H18V13H6.42C6.28 13 6.17 12.89 6.17 12.75L6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C19.96 3.34 20 3.17 20 3C20 2.45 19.55 2 19 2H4.21L3.27 0H0ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z"
            fill="inherit"
          />
        </svg>
      </div>
      <!-- Sauna / Jacuzzi -->
      <div class="app-navigation-entry" routerLink="control" routerLinkActive="active">
        <svg
          class="app-navigation-entry-icon"
          width="15"
          height="19"
          viewBox="0 0 15 19"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.49509 6.51647C1.46309 8.71547 1.50609 12.2015 3.62409 14.3605C4.65809 15.4175 6.03209 16.0005 7.49609 16.0015H7.50109C8.96809 16.0015 10.3471 15.4185 11.3861 14.3605C13.5421 12.1625 13.5381 8.58747 11.3771 6.39247L8.39109 3.34147C8.08909 5.82347 7.15809 8.00147 5.50009 8.00147C4.99209 8.00147 4.16409 7.77547 3.49509 6.51647V6.51647ZM7.50109 18.0015H7.49409C5.48809 17.9995 3.60609 17.2045 2.19509 15.7595C-0.718914 12.7895 -0.722914 7.95647 2.18609 4.99147L3.16909 3.99747C3.42309 3.74347 3.79209 3.64547 4.14109 3.73647C4.48809 3.83047 4.75809 4.10347 4.84809 4.45147C5.07809 5.34047 5.35209 5.77747 5.50309 5.94347C5.85809 5.56947 6.50009 4.05847 6.50009 1.50147C6.50009 1.35447 6.50009 1.21547 6.49209 1.07647C6.47309 0.781471 6.58609 0.492471 6.80009 0.288471C7.19709 -0.0885292 7.83309 -0.0885292 8.21409 0.303471L12.8041 4.99247C15.7241 7.95647 15.7291 12.7895 12.8141 15.7605C11.3951 17.2065 9.50909 18.0015 7.50109 18.0015V18.0015Z"
            fill="inherit"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.49509 6.51647C1.46309 8.71547 1.50609 12.2015 3.62409 14.3605C4.65809 15.4175 6.03209 16.0005 7.49609 16.0015H7.50109C8.96809 16.0015 10.3471 15.4185 11.3861 14.3605C13.5421 12.1625 13.5381 8.58747 11.3771 6.39247L8.39109 3.34147C8.08909 5.82347 7.15809 8.00147 5.50009 8.00147C4.99209 8.00147 4.16409 7.77547 3.49509 6.51647V6.51647ZM7.50109 18.0015H7.49409C5.48809 17.9995 3.60609 17.2045 2.19509 15.7595C-0.718914 12.7895 -0.722914 7.95647 2.18609 4.99147L3.16909 3.99747C3.42309 3.74347 3.79209 3.64547 4.14109 3.73647C4.48809 3.83047 4.75809 4.10347 4.84809 4.45147C5.07809 5.34047 5.35209 5.77747 5.50309 5.94347C5.85809 5.56947 6.50009 4.05847 6.50009 1.50147C6.50009 1.35447 6.50009 1.21547 6.49209 1.07647C6.47309 0.781471 6.58609 0.492471 6.80009 0.288471C7.19709 -0.0885292 7.83309 -0.0885292 8.21409 0.303471L12.8041 4.99247C15.7241 7.95647 15.7291 12.7895 12.8141 15.7605C11.3951 17.2065 9.50909 18.0015 7.50109 18.0015V18.0015Z"
            fill="inherit"
          />
        </svg>
      </div>
      <!-- Settings -->
      <div class="app-navigation-entry" routerLink="ambience" routerLinkActive="active">
        <svg
          class="app-navigation-entry-icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.9999 15.3099L23.3099 11.9999L19.9999 8.68994V3.99994H15.3099L11.9999 0.689941L8.68994 3.99994H3.99994V8.68994L0.689941 11.9999L3.99994 15.3099V19.9999H8.68994L11.9999 23.3099L15.3099 19.9999H19.9999V15.3099ZM11.9999 17.9999C8.68994 17.9999 5.99994 15.3099 5.99994 11.9999C5.99994 8.68994 8.68994 5.99994 11.9999 5.99994C15.3099 5.99994 17.9999 8.68994 17.9999 11.9999C17.9999 15.3099 15.3099 17.9999 11.9999 17.9999Z"
            fill="inherit"
          />
        </svg>
      </div>
      <!-- Music / Entertainment -->
      <div class="app-navigation-entry" routerLink="player" routerLinkActive="active">
        <svg
          class="app-navigation-entry-icon"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 0C4.03 0 0 4.03 0 9V16C0 17.66 1.34 19 3 19H6V11H2V9C2 5.13 5.13 2 9 2C12.87 2 16 5.13 16 9V11H12V19H15C16.66 19 18 17.66 18 16V9C18 4.03 13.97 0 9 0Z"
            fill="inherit"
          />
        </svg>
      </div>
      <!-- Information -->
      <div class="app-navigation-entry information" routerLink="information" routerLinkActive="active">
        <svg
          class="app-navigation-entry-icon"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 7H9V5H11V7ZM11 15L9 15L9 9H11L11 15ZM10.01 20C15.53 20 20 15.52 20 10C20 4.48 15.53 0 10.01 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10.01 20ZM10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2Z"
            fill="inherit"
          />
        </svg>
      </div>
    </div>
  </div>
  <div class="app-content" [class.app-danger]="kioskService.getRemainingTime() < 0">
    <router-outlet></router-outlet>
  </div>
</ng-container>

<div
  *ngIf="kioskService.room?.state !== 'OCCUPIED'"
  style="
    background: url('/assets/icons/relax_woman.png') no-repeat bottom left;
    height: 100vh;
    width: 100vw;
    position: absolute;
    padding-top: 100px;
  "
>
  <div style="text-align: center; font-weight: bold; font-size: 32px; margin-bottom: 40px">
    Keine aktive Sitzung!
  </div>
  <div style="text-align: center; font-size: 24px">{{ kioskService.room?.state }}</div>
</div>

<ng-template #adminPanel>
  <div style="padding: 30px" *ngIf="kioskService.activeStaff">
    <h1 style="font-weight: bold; font-size: 32px; text-align: center">Mitarbeiter Menu</h1>

    <table>
      <tr>
        <td style="width: 300px; padding-right: 50px">
          <span style="font-weight: bold">Angemeldet: </span>
          <br />
          <span>{{ kioskService.activeStaff.firstName }} {{ kioskService.activeStaff.lastName }}</span>
          <br />
          <br />
          <wlx-button (click)="kioskService.logoffStaff()">Ausloggen</wlx-button>
          <br />
          <br />
          <wlx-button (click)="kioskService.restartKiosk()">Gerät neustarten</wlx-button>
          <br />
          <br />
          <wlx-button
            *ngIf="kioskService.room.state !== 'CLEANING'"
            [disabled]="kioskService.room.state !== 'DIRTY'"
            (click)="kioskService.startCleaning()"
            >Reinigung starten</wlx-button
          >
          <wlx-button *ngIf="kioskService.room.state === 'CLEANING'" (click)="kioskService.completeCleaning()"
            >Reinigung beenden</wlx-button
          >
        </td>
        <td>
          <h2 style="font-size: 32px; font-weight: bold">Geräte</h2>
          <table style="border: 1px solid #000">
            <tr>
              <th style="border: 1px solid #000; font-weight: bold">Device</th>
              <th style="border: 1px solid #000; font-weight: bold"></th>
            </tr>
            <tr *ngFor="let device of kioskService.devices">
              <td style="vertical-align: top; border: 1px solid #000">{{ device.type }}</td>
              <td>
                <table style="border: 1px solid #000" *ngIf="device.properties">
                  <tr>
                    <th style="border: 1px solid #000; font-weight: bold">ID</th>
                    <th style="border: 1px solid #000; font-weight: bold">Value</th>
                  </tr>
                  <tr *ngFor="let property of device.properties">
                    <td style="border: 1px solid #000">{{ property.id }}</td>
                    <td style="border: 1px solid #000">
                      {{ property.value?.text }}{{ property.value?.number }}{{ property.value?.enum
                      }}{{ property.value?.picture }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <h2 style="font-size: 32px; font-weight: bold">Gäste</h2>
          <table style="border: 1px solid #000">
            <thead>
              <tr>
                <th>Name</th>
                <th>Zustand</th>
              </tr>
            </thead>
            <tr *ngFor="let guest of kioskService.activeSession?.guests || []">
              <td>{{guest.firstName}} {{guest.lastName}}</td>
              <td>{{guest.state}}</td>
            </tr>
          </table>
        </td>
        <td>
          <h2 style="font-size: 32px; font-weight: bold">Session</h2>
          <table>
            <tr>
              <td>id</td>
              <td>{{kioskService.activeSession?.id}}</td>
            </tr>
            <tr>
              <td>bookedSessionLength</td>
              <td>{{kioskService.activeSession?.bookedSessionLength}}</td>
            </tr>
            <tr>
              <td>sessionEnd</td>
              <td>{{kioskService.activeSession?.sessionEnd}}</td>
            </tr>
            <tr>
              <td>upgrades</td>
              <td>{{kioskService.activeSession?.upgrades?.join(', ') || 'n/a'}}</td>
            </tr>
          </table>
        </td>
        <td>
          <h2 style="font-size: 32px; font-weight: bold">Raum</h2>
          <table>
            <tr>
              <td>id</td>
              <td>{{kioskService.room?.id}}</td>
            </tr>
            <tr>
              <td>name</td>
              <td>{{kioskService.room?.name}}</td>
            </tr>
            <tr>
              <td>state</td>
              <td>{{kioskService.room?.state}}</td>
            </tr>
            <tr>
              <td>ssid</td>
              <td>{{kioskService.room?.ssid}}</td>
            </tr>
            <tr>
              <td>psk</td>
              <td>{{kioskService.room?.psk}}</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

    <!--    <pre>{{kioskService.devices | json}}</pre>-->
    <!--    <pre>{{kioskService.room | json}}</pre>-->
    <!--    <pre>{{kioskService.activeStaff | json}}</pre>-->
    <!--    <pre>{{kioskService.activeSession | json}}</pre>-->
    <!--    <pre>{{kioskService.basket | json}}</pre>-->
  </div>
</ng-template>

<ng-template #welcomeModal>
  <wlx-welcome-modal></wlx-welcome-modal>
</ng-template>


<ng-template #phonecallModal>
  <wlx-phone-call></wlx-phone-call>
</ng-template>

<small class="app-debug-line" (click)="kioskService.loginStaff('03513ac3')" (keypress)="kioskService.loginStaff('03513ac3')" tabindex="0"
  >{{ kioskService.getDebugLine() }}
  <!--<img style='width: 100px' [src]='kioskService.getDeviceProperty("SOUND_SYSTEM", "CURRENT_TITLE_COVER")?.value?.picture'/>--></small
>

<ng-template wlxDialogHost></ng-template>
