import { EventEmitter, Injectable } from '@angular/core';
import { KioskService } from './kiosk.service';
import * as nearley from 'nearley';
import { firstValueFrom, Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class WellyService {
  private prefix = '';

  private commands: { [key: string]: (args?: any[]) => Promise<void> } = {};

  public onDetect = new EventEmitter<string>();

  private timer: Subscription;

  private muted = false;
  private recognition: any;

  constructor(
    private kioskService: KioskService,
    private translateService: TranslateService
  ) {
    this.kioskService.sessionChange.subscribe(session => {
      return;
      if (session) this.unmute();
      else this.mute();
    });
    //this.startWelly();
    this.registerCommand('volumeUp', () => this.cmdVolumeUp());
    this.registerCommand('volumeDown', () => this.cmdVolumeDown());
    this.registerCommand('helpCall', () => this.cmdHelpCall());
    this.registerCommand('sceneChange', args => this.cmdSceneChange(args as string));
    this.registerCommand('lightDown', () => this.cmdLightDown());
    this.registerCommand('lightUp', () => this.cmdLightUp());
    this.registerCommand('saunaTemperatureChange', args => this.cmdSaunaTemperatureChange(args));
    this.registerCommand('sessionLength', () => this.cmdSessionLength());
    this.registerCommand('musicPlay', () => this.cmdMusicPlay());
    this.registerCommand('musicPause', () => this.cmdMusicPause());
    //this.parseCommand("hey welly stelle die sauna auf 75 grad")
    // setTimeout(() => {
    // await this.playTTSNotification(WellyClip.WELCOME, {firstName: this.kioskService.activeSession?.guests[0].firstName});
    // await this.playTTSNotification(`Hallo ${this.kioskService.activeSession?.guests[0].firstName}!
    //  Du bist eine Nase. Deine Nase befindet sich in ${this.kioskService.activeSession?.room?.name}.
    //   Du hast ${this.kioskService.activeSession?.bookedSessionLength} Minuten gebucht.
    //    Es befinden sich ${this.kioskService.activeSession?.guests?.length} Personen im Raum.`)

    // await this.cmdReadBasket();
    //await this.cmdSessionLength();
    //await this.playTTSNotification("Du Nase");
    // await this.playWellyClip(WellyClip.SESSION_15);
    //this.parseCommand("hey welly musik weiter")
    //this.parseCommand("hey welly ändere die szene zu stadt")
    //this.parseCommand("hey welly stelle die szene auf weltraum")
    //this.parseCommand("hey welly stelle die sauna auf 75 grad")
    //this.parseCommand("hey Welli wie lange habe ich noch den Raum")
    //await this.parseCommand("hey welly");
    //await this.parseCommand("musik leiser");

    // }, 4000)
  }

  registerCommand(command: string, cb: (args?: any) => Promise<void>) {
    this.commands[command] = cb;
  }

  async parseCommand(input: string): Promise<boolean> {
    if (input.length < 5) return false;
    try {
      const orginal = input;
      input = this.prefix + input.toLowerCase();

      /* eslint-disable */ // @ts-ignore
      /* eslint-disable */ const grammar = require('./welly.grammar');
      /* eslint-disable */ const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
      parser.feed(input);
      parser.finish();
      console.log(parser.results);
      if (parser.results.length === 0 && !this.prefix) {
        await this.playWellyClip(WellyClip.SILENCE);
        this.prefix = 'hey welly ';
        timer(5000).subscribe(() => (this.prefix = ''));
        return true;
      }

      const [command, args] = parser.results[0][0];
      //console.log('command', command);
      //console.log('args', args);
      if (!this.commands[command]) return false;
      this.onDetect.emit(orginal);
      await this.commands[command](args);
      this.prefix = '';
      return true;
    } catch (e) {
      //console.log(e);
      return false;
    }
  }

  mute() {
    this.muted = true;
    try {
      this.recognition?.stop();
    } catch (e) {
      console.log(e);
    }
  }

  unmute() {
    if (!this.muted) return;
    this.muted = false;
    this.startWelly();
  }

  async startWelly() {
    if (this.muted) return;
    // await navigator.mediaDevices
    //   .getUserMedia({ video: false, audio: true })
    try {
      /* eslint-disable */ // @ts-ignore
      const SpeechRecognition = webkitSpeechRecognition;
      /* eslint-disable */ // @ts-ignore
      const SpeechGrammarList = window.webkitSpeechGrammarList;
      /* eslint-disable */ // @ts-ignore
      const SpeechRecognitionEvent = webkitSpeechRecognitionEvent;
      //this.onDetect.emit();
      const recognition = new SpeechRecognition();
      this.recognition = recognition;
      const speechRecognitionList = new SpeechGrammarList();
      speechRecognitionList.addFromString(this.getWellyGrammar(), 1);
      recognition.grammars = speechRecognitionList;
      recognition.continuous = false;
      recognition.lang = 'de-DE';
      recognition.interimResults = true;
      recognition.maxAlternatives = 5;
      recognition.start();
      console.log('rec started');
      recognition.onresult = (event: any) => {
        console.log('result', event);
        this.playTTSNotification('hhhhhahuashdhuioashudiauisdhiu');
        // console.log( event.results[0][0].transcript);
        //console.log(event);
        if (this.prefix) this.onDetect.emit(this.prefix + event.results[0][0].transcript);
        this.timer?.unsubscribe();
        this.timer = timer(800).subscribe(async () => {
          for (const result of event.results[0]) {
            if (await this.parseCommand(result.transcript)) break;

            //console.log(result);
          }
          const transcript = event.results[0][0].transcript;
          //console.log(transcript);

          this.startWelly();
        });

        // diagnostic.textContent = `Result received: ${color}.`;
        // bg.style.backgroundColor = color;
        // console.log(`Confidence: ${event.results[0][0].confidence}`);
      };

      recognition.onspeechend = () => {
        this.startWelly();
        //recognition.stop();
      };

      recognition.onnomatch = () => {
        //console.log("I didn't recognise that color.");
      };

      recognition.onerror = (event: any) => {
        console.log(event);
        this.startWelly();
        //console.log('Error occurred in recognition: ' + event.error);
      };
    } catch (e) {
      console.log(e);
      // alert(e);
    }
  }

  getWellyGrammar() {
    return `#JSGF V1.0
    public <basicCmd> = <startPhrase> <command>;

    <command> = <volumeDown> | <volumeUp> | <helpCall> | <sceneChange> | <saunaTemperatureChange> | <lightDown> | <lightUp> | <sessionLength>;

    <volumeDown> = musik leiser | lautstärke niedriger;
    <volumeUp> = musik lauter | lautstärke höher;
    <helpCall> = hilfe | ich brauche hilfe;
    <sceneChange> = ändere die szene zu <scene>;
    <saunaTemperatureChange> = stelle die sauna auf <temperature> [Grad];
    <lightDown> = licht dunkler;
    <lightUp> = licht heller;
    <sessionLength> = wie lange (geht | dauert) meine (session | sitzung) | wie lange habe ich noch den raum | wie lange habe ich noch zeit;


    <temperature> = (40 | 45 | 50 | 55 | 60 | 65 | 70 | 75 | 80);
    <scene> = strand | berg | stadt | weltraum;
    <startPhrase> = (hey welly | hallo welly) *;
    `;
  }

  private async cmdVolumeDown() {
    await this.playWellyClip(WellyClip.GONG);
    let volume = this.kioskService.getDeviceProperty('SOUND_SYSTEM', 'VOLUME')?.value?.number || 0;
    volume -= 0.1;
    console.log(volume);
    if (volume < 0) return;
    await this.kioskService.updateDeviceProperty('SOUND_SYSTEM', 'VOLUME', { number: volume });
  }

  private async cmdVolumeUp() {
    await this.playWellyClip(WellyClip.GONG);
    let volume = this.kioskService.getDeviceProperty('SOUND_SYSTEM', 'VOLUME')?.value?.number || 0;
    volume += 0.1;
    console.log(volume);
    if (volume > 1) return;
    await this.kioskService.updateDeviceProperty('SOUND_SYSTEM', 'VOLUME', { number: volume });
  }

  private async cmdHelpCall() {}

  private async cmdSceneChange(scene: string) {}

  public async cmdReadBasket() {
    const basket = this.kioskService.basket
      .map(
        item =>
          `${item.quantity} mal ${this.kioskService.getProductById(item.productId).name} für jeweils ${
            this.kioskService.getProductById(item.productId).price / 100
          }€`
      )
      .join(';');
    const total =
      this.kioskService.basket
        .map(item => (this.kioskService.getProductById(item.productId)?.price || 0) * item.quantity)
        .reduce((a, b) => a + b, 0) / 100;

    await this.playTTSNotification(
      `Dein Warenkorb enthält: ${basket}. Der Gesamtbetrag beträgt ${total}€."`
    );
    // setTimeout(() => {
    //   this.prefix = 'hey welly'
    // }, 3000);
  }

  private cmdLightDown() {
    return Promise.resolve(undefined);
  }

  private cmdLightUp() {
    return Promise.resolve(undefined);
  }

  private cmdSaunaTemperatureChange(args: any) {
    return Promise.resolve(undefined);
  }

  public async cmdSessionLength() {
    const length = Math.floor(this.kioskService.getRemainingTime() / 60);
    if (length > 0)
      await this.playTTSNotification(
        `Dir verbleiben noch ${length} Minuten bis zum Ende deiner Auszeit.`
      );
    else await this.playTTSNotification(`Deine Auszeit ist zu Ende`);
    //await this.playWellyClip(WellyClip.SESSION_15);
  }

  async playWellyClip(clip: WellyClip) {
    const baseUrl = window.location.origin;
    await this.playNotification(baseUrl + clip);
  }

  async playTTSNotification(languageKey: WellyClip | string, params: any = {}) {
    const text = await firstValueFrom(this.translateService.get(languageKey, params));
    await this.kioskService.playTTSNotification(text);
  }

  async playNotification(url: string) {
    await this.kioskService.playNotification(url);
  }

  private async cmdMusicPlay() {
    await this.kioskService.updateDeviceProperty('SOUND_SYSTEM', 'STATE', { enum: 'PLAY' });
  }

  private async cmdMusicPause() {
    await this.kioskService.updateDeviceProperty('SOUND_SYSTEM', 'STATE', { enum: 'PAUSE' });
  }
}

export enum WellyClip {
  WELCOME = 'welly.welcome',
  GONG = '/assets/welly/notification.wav',
  SILENCE = '/assets/welly/notification.wav',
  SESSION_15 = '/assets/welly/session/15.wav',
  SESSION_5 = '/assets/welly/session/30.wav',
  SESSION_0 = '/assets/welly/notification.wav',
}
