import { Component } from '@angular/core';
import { ButtonModule, DialogService } from '@wellext/kit';
import { QrCodeModule } from 'ng-qrcode';
import { KioskService } from '../../kiosk.service';

@Component({
  selector: 'wlx-welcome-modal',
  standalone: true,
  imports: [ButtonModule, QrCodeModule],
  templateUrl: './welcome-modal.component.html',
  styleUrl: './welcome-modal.component.css',
})
export class WelcomeModalComponent {
  constructor(
    private dialogService: DialogService,
    public kioskService: KioskService
  ) {}

  close() {
    this.dialogService.close();
  }

  getWlanQrString() {
    return `WIFI:T:WPA;S:${this.kioskService.room.ssid};P:${this.kioskService.room.psk};;`;
  }
}
