import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ShopModule } from './views/shop/shop.module';
import { KioskService } from './kiosk.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiModule } from '@wellext/spa-api';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { environment } from '../environments/environment';
import { NfcReaderService } from './nfc-reader.service';
import { WellextKitModule } from '@wellext/kit';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PlayerControlsModule } from './components/player-controls/player-controls.module';
import { PlayerSeekerModule } from './components/player-seeker/player-seeker.module';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { PhoneCallComponent } from './components/phone-call/phone-call.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    ApiModule,
    AppRoutingModule,
    BrowserModule,
    ShopModule,
    WellextKitModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    PlayerControlsModule,
    PlayerSeekerModule,
    WelcomeModalComponent,
    PhoneCallComponent,
  ],
  providers: [
    KioskService,
    NfcReaderService,
    {
      provide: InjectableRxStompConfig,
      useValue: <InjectableRxStompConfig>{
        // brokerURL: 'ws://10.2.0.192:15673/ws',

        brokerURL: environment.brokerUrl,
        connectHeaders: {
          login: 'admin',
          passcode: 'guest',
        },
        heartbeatIncoming: 0,
        heartbeatOutgoing: 1000 * 5,
        reconnectDelay: 500,
        // debug: (msg: string): void => {
        //   console.log(new Date(), msg);
        // },
      },
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
