import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { KioskService } from './kiosk.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { firstValueFrom, Subscription, timer } from 'rxjs';
import { WellyService } from './welly.service';
import { DialogService } from '@wellext/kit';
import { PhoneService } from './views/phone.service';
import { RoomService } from '@wellext/spa-api';

@Component({
  selector: 'wlx-kiosk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'wlx-kiosk';
  private timer: Subscription;

  @ViewChild('adminPanel')
  private adminPanel: TemplateRef<any>;

  @ViewChild('welcomeModal')
  private welcomeModal: TemplateRef<any>;

  @ViewChild('phonecallModal')
  private phonecallModal: TemplateRef<any>;

  constructor(
    public kioskService: KioskService,
    private wellyService: WellyService,
    private router: Router,
    public dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private phoneService: PhoneService,
    private viewContainerRef: ViewContainerRef,
    private roomService: RoomService
  ) {}

  async ngOnInit() {
    /* eslint-disable */
    setTimeout(async () => {
      try {
        const authKey =
          this.activatedRoute.snapshot.queryParamMap.get('authKey') ||
          localStorage.getItem('authKey');

        let deviceIdByRoomId;

        const deviceId = this.activatedRoute.snapshot.queryParamMap.get('deviceId');
        if (deviceId) {
          try {
            const roomIdResponse = await firstValueFrom(
              this.roomService.getRoomIdByDeviceId({
                deviceId,
              })
            );
            deviceIdByRoomId = roomIdResponse.id;
          } catch (e) {
            throw new Error(`INIT_DEVICE DeviceID: ${deviceId} - AuthKey: ${authKey}`);
          }
        }

        const roomId =
          this.activatedRoute.snapshot.queryParamMap.get('roomId') ||
          deviceIdByRoomId ||
          localStorage.getItem('roomId');

        const roomIdNotSpecified = roomId === '' || roomId === undefined || roomId === null;
        const authKeyNotSpecified = authKey === '' || authKey === undefined || authKey === null;
        if (roomIdNotSpecified) {
          throw new Error('missig roomId query param, cannot init');
        }
        if (authKeyNotSpecified) {
          throw new Error('missig authKey query param, cannot init');
        }

        localStorage.setItem('roomId', roomId);
        localStorage.setItem('authKey', authKey);

        this.kioskService.setAuthKey(authKey);
        await this.kioskService.initKiosk(roomId, this.welcomeModal, this.viewContainerRef);
        this.phoneService.setModal(this.phonecallModal, this.viewContainerRef);

        this.router.events.subscribe((e: any) => {
          if (!(e instanceof NavigationEnd)) return;
          this.timer?.unsubscribe();
          this.timer = timer(1000 * 60 * 10).subscribe(() => {
            // if(this.router.url != '/')
            //   this.router.navigate(['/']);
          });
        });

        /* eslint-disable */
        this.kioskService.timeChange.subscribe(async time => {
          switch (time) {
            case 0:
              this.dialogService.showMessageDialog(
                'Feierabend',
                'Ihre Sitzung ist nun beendet. Wir bitten Sie, den Raum zu verlassen. Wir hoffen, Sie hatten eine erholsame Zeit und freuen uns auf Ihren nächsten Besuch.'
              );
              try {
                await this.wellyService.cmdSessionLength();
              } catch (error) {
                console.log(error);
              }
              break;
            case 60 * 5:
              this.dialogService.showMessageDialog(
                '5 Minuten verbleiben',
                'Bitte machen Sie sich langsam bereit, den Raum zu verlassen und nehmen Sie die Ruhe mit in Ihren Alltag.',
                1000 * 60,
                true,
                'Schliessen'
              );
              try {
                await this.wellyService.cmdSessionLength();
              } catch (error) {
                console.log(error);
              }
              break;
            case 60 * 15:
              this.dialogService.showMessageDialog(
                '15 Minuten verbleiben',
                'Genießen Sie Ihre Entspannung in vollen Zügen. Lassen Sie die Ruhe auf sich wirken und gönnen Sie sich die letzten Momente völliger Gelassenheit.',
                1000 * 60,
                true,
                'Schliessen'
              );
              try {
                await this.wellyService.cmdSessionLength();
              } catch (error) {
                console.log(error);
              }
              break;
          }
        });

        this.wellyService.onDetect.subscribe(msg => {
          if (!msg) return;
          console.log('msg', msg);
          // this.detect = msg
          //this.dialogService.showErrorDialog('welly', msg);
        });

        this.kioskService.staffChange.subscribe(staff => {
          console.log('staff change', staff);
          if (!staff) this.dialogService.close();
          else this.dialogService.open(this.viewContainerRef, this.adminPanel);
        });
      } catch (e: any) {
        this.dialogService.showErrorDialog(e);
        setTimeout(() => window.location.reload(), 15 * 1000);
      }

      // await this.kioskService.resetKiosk();
      // await this.kioskService.reloadSession();
    }, 1000);
  }
}
