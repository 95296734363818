import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const routes: Array<Route> = [
  {
    path: 'shop',
    loadChildren: () => import('./views/shop/shop.module').then(m => m.ShopModule),
  },
  {
    path: 'shop/processing-order',
    loadChildren: () =>
      import('./views/processing-order/processing-order.module').then(m => m.ProcessingOrderModule),
  },
  {
    path: 'shop/upgrades',
    loadChildren: () =>
      import('./views/shop-upgrades/shop-upgrades.module').then(m => m.ShopUpgradesModule),
  },
  {
    path: 'shop/:categoryId',
    loadChildren: () =>
      import('./views/shop-listing/shop-listing.module').then(m => m.ShopListingModule),
  },
  {
    path: 'player',
    loadChildren: () => import('./views/player/player.module').then(m => m.PlayerModule),
  },
  {
    path: 'information',
    loadChildren: () =>
      import('./views/information/information.module').then(m => m.InformationModule),
  },
  {
    path: 'basket',
    loadChildren: () => import('./views/basket/basket.module').then(m => m.BasketModule),
  },
  {
    path: 'order',
    loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'control',
    loadChildren: () => import('./views/control/control.module').then(m => m.ControlModule),
  },
  {
    path: 'ambience',
    loadChildren: () => import('./views/ambience/ambience.module').then(m => m.AmbienceModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
