import { Component } from '@angular/core';
import { ButtonModule } from '@wellext/kit';
import { PhoneService } from '../../views/phone.service';

@Component({
  selector: 'wlx-phone-call',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './phone-call.component.html',
  styleUrl: './phone-call.component.css',
})
export class PhoneCallComponent {
  constructor(private phoneService: PhoneService) {}

  disconnect() {
    this.phoneService.disconnect();
  }
}
