<div class="">
  <div class="grid grid-cols-12 gap-4">

    <div class="col-span-12">
      <h1 class="text-3xl font-bold text-center">
        Herzlich Willkommen
      </h1>
    </div>


    <div class="col-span-4 justify-items-center">
      <qr-code [value]="getWlanQrString()" size="300"></qr-code>

      <div>
        WLAN: {{kioskService.room.ssid}}
      </div>
      <div>
        Passwort: {{kioskService.room.psk}}
      </div>
    </div>

    <div class="col-span-8">
      <ol class="list-decimal mb-5">
        <li>Scanne den QR-Code und verbinde dich mit unserem WLAN.</li>
        <li>Öffne eine Musik- oder Video-App wie Spotify, Netflix oder YouTube.</li>
        <li>
          Achte auf folgende Symbole:
          <img class="h-16 inline ml-5" src="/assets/icons/airplay.png" alt="AirPlay" />
          <img class="h-16 inline ml-5" src="/assets/icons/chromecast.svg" alt="Chromecast" />
        </li>
        <li>
          Wähle "wellbase" aus der Liste aus
        </li>
      </ol>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
      sed diam nonumy eirmod tempor invidunt ut labore et dolore
      magna aliquyam erat, sed diam voluptua. At vero eos et accusam
      et justo duo dolores et ea rebum. Stet clita kasd gubergren,
      no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
      ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
      erat, sed diam voluptua. At vero eos et accusam et justo duo
      dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
      sanctus est Lorem ipsum dolor sit amet.

      <div class="mt-5">
        <wlx-button (click)="close()">Beginnen</wlx-button>
      </div>
    </div>

  </div>
</div>
